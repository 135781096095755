import React, { Component } from 'react';
import DeleteConfirm from './DeleteConfirm';
import { connect } from 'react-redux';
import { staffReducer } from '../../../reducers/staffReducer';
import { getBookingList, cancelBookingForSalon, notifyAction } from '../../../actions';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import moment from 'moment';
import Func from '../../../utils/Func';

class ReservationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      bookingsDisplay: [],
      searchValue: '',
      showDelete: false,
      idDelete: null,
      hiddenCards: {},
      isMobile: window.innerWidth < 769,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.isModeReservationManagement = props.mode == 'ReservationManagement';
  }

  componentDidMount() {
    // this.fetchBookings(this.props.date);

    if (!this.isModeReservationManagement) {
      this.props.getBookingList({ date: moment(this.props.date).format('YYYY-MM-DD') });
    }
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 769 });
  };

  UNSAFE_componentWillReceiveProps(props) {
    console.log('props: ', props);
    const { type } = props.actionNotify;
    const { errorCode, message } = Func.getError({ ...props });
    switch (type) {
      case TYPES.ACTION_GET_BOOKING_LIST_SUCCESSFULLY:
        const bookings = _.get(props.actionNotify, 'bookingList', []);
        this.setState({ bookings, bookingsDisplay: bookings });
        break;
      case TYPES.ACTION_GET_BOOKING_LIST_FAIL:
      case TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL:
        break;
      case TYPES.ACTION_CANCEL_BOOKING_SUCCESSFULLY:
        this.setState(
          {
            showDelete: false,
            idToDelete: null,
          },
          () => {
            this.props.getBookingList({ date: moment(this.props.date).format('YYYY-MM-DD') });
          }
        );

        break;
      case TYPES.ACTION_CANCEL_BOOKING_FAIL:
        break;
      default:
        break;
    }

    this.props.notifyAction({ type, error_code: errorCode, message });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date && !this.isModeReservationManagement) {
      this.props.getBookingList({ date: moment(this.props.date).format('YYYY-MM-DD') });
    }
  }

  // async fetchBookings(date) {

  //     const bookingData = [];
  //     for (let i = 0; i < 5; i++) {
  //         bookingData.push({
  //             id: i,
  //             startTime: `11:00`,
  //             endTime: `12:00`,
  //             name: `Customer ${Math.floor(Math.random() * 5)}`,
  //             phoneNumber: `012345678${i}`,
  //             option: `Option aaaaaaaaa ${Math.floor(Math.random() * 5)}`
  //         });
  //     }
  //     this.setState({ bookings: bookingData });
  // }

  handleDelete() {
    const bookingUUID = this.state.bookingsDisplay[this.state.idDelete].booking_uuid;
    this.props.cancelBookingForSalon({ booking_uuid: bookingUUID });
  }

  handleCancelDelete = () => {
    this.setState({
      showDelete: false,
      idDelete: null,
    });
  };

  selectDelete(id) {
    console.log('id: ', id);
    this.setState({
      idDelete: id,
      showDelete: true,
    });
  }

  handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchValue }, () => {
      if (!searchValue) {
        this.handleSearch();
      }
    });
  };
  onEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const { searchValue } = this.state;
    let searchKey = searchValue || '';
    // if (searchValue === '') {
    //     this.fetchBookings(this.props.date);
    //     return;
    // }
    const filterBookings = this.state.bookings.filter(
      (booking) =>
        booking.name.toLowerCase().includes(searchKey.toLowerCase()) || // search by name
        (booking.phoneNumber || '').indexOf(searchKey) > -1 || // search by phoneNumber
        (booking.option || '').indexOf(searchKey) > -1 // search by option
    );

    this.setState({ bookingsDisplay: filterBookings });
  };

  DetailBookingTable = (option) => {
    const maxLength = 80;
    if (option.length > maxLength) {
      return option.substring(0, maxLength) + '...';
    }
    return option;
  };
  toggleCardVisibility = (id) => {
    this.setState((prevState) => ({
      hiddenCards: {
        ...prevState.hiddenCards,
        [id]: !prevState.hiddenCards[id],
      },
    }));
  };
  render() {
    let { isMobile, hiddenCards } = this.state;

    return (
      <div className="d-flex justify-content-center text-center mt-20">
        <div className="w-100 d-flex flex-column" style={{ maxWidth: '1200px' }}>
          {!isMobile ? (
            <table className="table table-bordered table-booking table-reservation">
              <thead className="head-table font-24">
                <tr>
                  <th className="w-9" style={{ maxWidth: '100px' }}>
                    時間帯
                  </th>
                  <th className="w-20" style={{ maxWidth: '250px' }}>
                    名前
                  </th>
                  <th className="w-12" style={{ maxWidth: '250px' }}>
                    携帯番号
                  </th>
                  <th className="w-25" style={{ maxWidth: '300px' }}>
                    コース
                  </th>
                  <th className="w-24" style={{ maxWidth: '300px' }}>
                    ご来店の目的・ご要望・ご質問
                  </th>
                  <th className="w-10" style={{ maxWidth: '100px' }}>
                    削除
                  </th>
                </tr>
              </thead>
              <tbody className="text-dark font-weight-bold font-20">
                {this.state.bookingsDisplay.map((detail, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                    <td className="time-table">
                      <span>{detail.startTime}</span>
                      <br />
                      <span>{detail.endTime}</span>
                    </td>
                    <td className=" text-left">{detail.name}</td>
                    <td className="text-left">{detail.phoneNumber}</td>
                    <td className="text-left">{this.DetailBookingTable(detail.option)}</td>
                    <td className="text-left">{detail.purpose}</td>

                    <td className="table-td_icon" style={{ verticalAlign: 'middle', color: 'red', cursor: 'pointer' }}>
                      <i onClick={() => this.selectDelete(detail.id)} className="fa fa-trash text-center" aria-hidden="true"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="w-100">
              {this.state.bookingsDisplay.map((detail, index) => (
                <div key={index} className={`card card-custom-ms text-dark w-100 card-optionmanage`}>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="card-list">
                        <h4 className="card-title card-time">時間帯</h4>
                        <p className="card-text">
                          <span>{detail.startTime}</span>
                          <br />
                          <span>{detail.endTime}</span>
                        </p>
                      </div>

                      <div className="card-list">
                        <h4 className="card-title card-name">名前</h4>
                        <p className="card-text">{detail.name}</p>
                      </div>
                      {!hiddenCards[detail.id] && (
                        <div>
                          <div className="card-list">
                            <h4 className="card-title card-phone">携帯番号</h4>
                            <p className="card-text">{detail.phoneNumber}</p>
                          </div>

                          <div className="card-list">
                            <h4 className="card-title card-option">コース</h4>
                            <p className="card-text text-left">{this.DetailBookingTable(detail.option)}</p>
                          </div>
                          <div className="card-list">
                            <h4 className="card-title card-option">ご来店の目的・ご要望・ご質問</h4>
                            <p className="card-text text-left">{detail.purpose}</p>
                          </div>

                          <div className="card-list card-list-edit">
                            <h4 className="card-title card-title-icon">アクション</h4>
                            <div className="card-list-icon">
                              <i style={{ color: 'red' }} onClick={() => this.selectDelete(detail.id)} className="fa fa-trash text-center card-icon" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div className="card-toggle">
                        <button onClick={() => this.toggleCardVisibility(detail.id)} className="btn btn-card-toggle">
                          {!hiddenCards[detail.id] ? '情報を表示' : '情報を隠す'}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="search-container d-flex justify-content-between align-items-center">
            <input
              type="text"
              placeholder="キーワード検索"
              className="form-control mr-2 w-90 px-10 text-dark h-100"
              style={{ borderWidth: '2px', minHeight: 50, fontWeight: 700 }}
              value={this.state.searchValue}
              onChange={this.handleSearchInputChange}
              onKeyDown={this.onEnter}
            />
            <button onClick={this.handleSearch} className="btn btn-success w-5 h-100 " style={{ minWidth: 100 }}>
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        {this.state.showDelete && this.state.idDelete !== null && (
          <DeleteConfirm bookings={this.state.bookings} id={this.state.idDelete} onConfirm={this.handleDelete} onCancel={this.handleCancelDelete} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;
  return {
    actionNotify: staffReducer.actionNotify,
  };
}

export default connect(mapStateToProps, {
  getBookingList,
  cancelBookingForSalon,
  notifyAction,
})(ReservationTable);
