import { error } from 'jquery';
import { TYPES, bookingCountForMonth } from '../actions';
import _ from 'lodash';
import moment from 'moment';
import Func from '../utils/Func';

const initialState = {
  staff_list: {},
  actionNotify: {
    time: +new Date(),
  },
  guessChoicing: {},
  bookingCountForMonth: {
    ts: +new Date(),
    data: {},
  },
};

export const staffReducer = (state = initialState, action) => {
  // console.log('staffReducer action: ', action);
  // console.log('staffReducer state: ', state);
  let error_code = _.get(action, 'payload.data.error_code') || _.get(action, 'payload.message.error_code');
  const error_message = _.get(action, 'payload.data.message') || _.get(action, 'payload.message.message');
  if (_.get(error_message, 'message')) {
    error_code = -1;
  }
  switch (action.type) {
    case TYPES.ACTION_STAFF_GET_LIST_SUCCESS:
      return {
        ...state,
        staff_list: _.pick(action.payload.data, ['total_record', 'data']),
      };

    case TYPES.ACTION_SAVE_SHIFT_SUCCESSFULLY:
    case TYPES.ACTION_STAFF_UPDATE_INFO_SUCCESS:
    case TYPES.ACTION_CREATE_SERVICE_SUCCESSFULLY:
    case TYPES.ACTION_DELETE_SERVICE_SUCCESSFULLY:
    case TYPES.ACTION_CREATE_BOOKING_SUCCESSFULLY:
    case TYPES.ACTION_CREATE_GUESS_USER_SUCCESSFULLY:
    case TYPES.ACTION_STAFF_CHANGE_PASSWORD_SUCCESS:
    case TYPES.ACTION_STAFF_FORGET_PASSWORD_SUCCESS:
    case TYPES.ACTION_STAFF_RESET_PASSWORD_SUCCESS:
    case TYPES.ACTION_CANCEL_BOOKING_SUCCESSFULLY:
    case TYPES.ACTION_SALON_LINK_LINE_ACCOUNT_SUCCESS:
    case TYPES.ACTION_CHECK_BOOKING_AVAILABLE_SUCCESSFULLY:
    case TYPES.ACTION_SERVICE_PRIORITY_VALID:
    case TYPES.ACTION_GUEST_LINE_USER_ID_VALID:
    case TYPES.ACTION_SALON_UPDATE_WORKING_TIME_SUCCESS:
    case TYPES.ACTION_SALON_UPDATE_SETTINGS_SUCCESS:
    case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          data: action.payload.data,
        },
      };
    case TYPES.ACTION_SAVE_SHIFT_FAIL:
    case TYPES.ACTION_CREATE_SERVICE_FAIL:
    case TYPES.ACTION_GET_LIST_SERVICES_FAIL:
    case TYPES.ACTION_DELETE_SERVICE_FAIL:
    case TYPES.ACTION_GET_GUESS_LIST_SERVICES_FAIL:
    case TYPES.ACTION_GET_GUESS_INFO_FAIL:
    case TYPES.ACTION_CREATE_BOOKING_FAIL:
    case TYPES.ACTION_CREATE_GUESS_USER_FAIL:
    case TYPES.ACTION_CALL_API_FAIL:
    case TYPES.ACTION_GET_BOOKING_LIST_FAIL:
    case TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL:
    case TYPES.ACTION_CANCEL_BOOKING_FAIL:
    case TYPES.ACTION_GET_GUESS_BOOKING_FAIL:
    case TYPES.ACTION_CHECK_BOOKING_AVAILABLE_FAIL:
    case TYPES.ACTION_SERVICE_PRIORITY_INVALID:
    case TYPES.ACTION_GUEST_LINE_USER_ID_INVALID:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: error_code,
          message: error_message,
        },
      };

    case TYPES.ACTION_GET_SHIFT_SUCCESSFULLY:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          shiftDetail: _.get(action, 'payload.data.data'),
          bookingFrames: _.get(action, 'payload.data.booking_frames'),
          // startTime: _.get(action, 'payload.data.start_time'),
          // endTime: _.get(action, 'payload.data.end_time'),
        },
      };

    case TYPES.ACTION_GET_SHIFT_FAIL:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: error_code,
          message: error_message,
          shiftDetail: _.get(action, 'payload.data.booking_frames', '{}'),
          // '{"_":{"10_30":0,"11_00":0,"11_30":0,"12_00":0,"12_30":0,"13_00":0,"13_30":0,"14_00":0,"14_30":0,"15_00":0,"15_30":0,"16_00":0,"16_30":0,"17_00":0,"17_30":0,"18_00":0,"18_30":0,"19_00":0,"19_30":0,"20_00":0,"20_30":0,"21_00":0,"21_30":0}}',
        },
      };

    case TYPES.ACTION_GET_LIST_SERVICES_SUCCESSFULLY:
      const servicesData = _.get(action, 'payload.data.data', []);
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          services: servicesData.map((item, index) => {
            return {
              id: index,
              content: item.name,
              price: `${item.price}円`,
              frames: item.total_time,
              db_id: item.id,
              priority: item.priority,
            };
          }),
        },
      };

    case TYPES.ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          schedules: action.payload.data.data,
          booking_frames: _.get(action.payload.data, 'booking_frames', []),
          start_time: action.payload.data.start_time.replace('_', ':'),
          end_time: action.payload.data.end_time.replace('_', ':'),
        },
      };
    case TYPES.ACTION_GET_LIST_SCHEDULE_FAIL:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: error_code,
          message: error_message,
          ...(action.payload.data
            ? {
                booking_frames: _.get(action.payload.data, 'booking_frames', []),
                start_time: action.payload.data.start_time.replace('_', ':'),
                end_time: action.payload.data.end_time.replace('_', ':'),
              }
            : {}),
        },
      };
    case TYPES.ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          service: action.payload.data.data,
        },
      };
    case TYPES.ACTION_SET_GUESS_CHOICE:
    case TYPES.ACTION_CLEAN_GUEST_CHOICE:
      const guessChoicing = { ...state.guessChoicing, ...action.payload.data };
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
        },
        guessChoicing: guessChoicing,
      };
    case TYPES.ACTION_GET_GUEST_CHOICE:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          guessChoicing: action.payload.data,
        },
        guessChoicing: action.payload.data,
      };

    case TYPES.ACTION_GET_GUESS_INFO_SUCCESSFULLY:
      const data = action.payload.data.data;
      const genderCode = data.gender === 'male' ? 0 : data.gender === 'female' ? 1 : data.gender === 'other' ? 2 : null;
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          guessInfo: {
            name: action.payload.data.data.name || '',
            phone: action.payload.data.data.phone || '',
            birthYear: action.payload.data.data.birthday || null,
            gender: genderCode,
            purpose: action.payload.data.data.purpose || '',
          },
        },
      };
    case TYPES.ACTION_GET_BOOKING_LIST_SUCCESSFULLY:
      const bookingList = [...action.payload.data.data] ?? [];
      let bookingListFormatted = bookingList
        .map((booking) => {
          const option = Func.isValidJSON(booking.booking_details) ? JSON.parse(booking.booking_details) : {};
          const total_time = _.get(option, 'service.total_time', 0);
          const startHour = moment(booking.booking_time).format('HH:mm');
          const endHour = moment(booking.booking_time)
            .add(total_time * 30, 'minutes')
            .format('HH:mm');
          return {
            booking_uuid: booking.code,
            name: booking.name,
            phoneNumber: booking.phone,
            startTime: startHour,
            endTime: endHour,
            option: _.get(option, 'service.name', ''),
            purpose: booking.purpose,
          };
        })
        .sort((a, b) => a.startTime.localeCompare(b.endTime));
      bookingListFormatted = bookingListFormatted.map((b, i) => {
        return {
          ...b,
          id: i,
        };
      });
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          bookingList: bookingListFormatted,
        },
      };

    case TYPES.ACTION_GET_GUESS_BOOKING_SUCCESSFULLY:
      const booking = _.get(action, 'payload.data.data', {});
      const bookingTime = moment(booking.booking_time);
      const option = Func.isValidJSON(booking.booking_details) ? JSON.parse(booking.booking_details) : {};
      const total_time = _.get(option, 'service.total_time', 0);
      const gender = _.get(booking, 'gender', '');
      const formatedBooking = {
        salonName: _.get(booking, 'salon_name', ''),
        visitDateTime: bookingTime.format(`MM月DD日（${Func.getJapaneseDayOfWeek(bookingTime.day())}）HH:mm`),
        totalDuration: `${Func.convertToJapaneseTime(total_time)}`,
        options: _.get(option, 'service.name', ''),
        totalPrice: `${_.get(option, 'service.price', 0)}円`,
        customerName: `${_.get(booking, 'user_name', '')}`,
        phoneNumber: `${_.get(booking, 'phone', '')}`,
        birthYear: `${moment(_.get(booking, 'birthday', '')).format('YYYY')}`,
        sex: gender === 'male' ? '男性' : gender === 'female' ? '女性' : 'その他',
        purpose: _.get(booking, 'purpose', ''),
      };
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          booking: formatedBooking,
        },
      };

    case TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_SUCCESSFULLY:
      return {
        ...state,
        bookingCountForMonth: {
          ts: +new Date(),
          data: {
            ...state.bookingCountForMonth.data,
            ..._.get(action.payload, 'data.data', {}),
          },
        },
      };

    default:
      return state;
  }
};
